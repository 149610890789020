import React from 'react';
import { Avatar, Box, Card, Rating, Stack, Typography } from '../../lib/base';
import { Link } from '../../lib/link';

interface TestimonialProps {
  readonly content: string;
  readonly avatarSrc?: string;
  readonly link?: string;
  readonly name: string;
  readonly title?: string;
  readonly align?: 'left' | 'right' | 'center';
  readonly fullWidth?: boolean;
}

export const Testimonial = ({
  content,
  avatarSrc,
  link,
  name,
  title,
  align = 'left',
  fullWidth = true,
}: TestimonialProps) => {
  return (
    <Card
      sx={{
        p: 2,
        textAlign: align,
        width: fullWidth ? '100%' : 'auto',
        backgroundColor: 'primary.light',
        color: 'white',
      }}
    >
      <Stack spacing={2} alignItems={align}>
        <Rating value={5} readOnly />

        <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
          "{content}"
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {avatarSrc && <Avatar src={avatarSrc} sx={{ width: 64, height: 64 }} />}
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              {name}
            </Typography>
            {title && (
              <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                {title}
              </Typography>
            )}
          </Box>
        </Box>

        {link && <Link newTab path={link} title="View Full Review" />}
      </Stack>
    </Card>
  );
};
